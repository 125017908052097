import gsap from 'gsap'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const setupNavigation = () => {
  const pageContainer = document.querySelector('[data-content]')
  const navigation = document.querySelector('[data-component="nav"]')
  const openBtn = document.querySelector('[data-component="nav-open"]')
  const navigationContainer = navigation.querySelector('[data-container]')
  const overlay = document.querySelector('[data-overlay]')
  const closeBtn = navigation.querySelector('[data-close]')
  const backBtn = navigation.querySelector('[data-back]')
  const defaultNav = navigation.querySelector('[data-default]')
  const defaultLinks = defaultNav.querySelectorAll('li')
  const langPicker = navigation.querySelector('[data-lang]')
  const parents = navigation.querySelectorAll('[data-parent]')
  const subnavs = navigation.querySelectorAll('[data-subnav]')
  const contact = navigation.querySelector('[data-contact]')

  let subnavActive = false
  let activeSubnav = null
  let navigationOpen = false

  const transitionIn = gsap.timeline({
    paused: true,
    defaults: {
      ease: 'expo.out',
    },
  })

  transitionIn.add('start', 0)

  transitionIn.fromTo(
    navigation,
    {
      xPercent: 100,
    },
    {
      xPercent: 0,
      duration: 1,
    },
  )

  transitionIn.fromTo(
    defaultLinks,
    {
      y: '-25px',
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 1,
      stagger: {
        from: 'end',
        amount: 0.25,
      },
    },
    '-=0.75',
  )

  transitionIn.fromTo(
    langPicker,
    {
      y: '-25px',
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 0.6,
    },
    '<',
  )

  transitionIn.fromTo(
    contact,
    {
      x: '50px',
      opacity: 0,
    },
    {
      x: 0,
      opacity: 1,
      duration: 0.6,
    },
    'start+=0.4',
  )

  transitionIn.fromTo(
    closeBtn,
    {
      rotate: '-45deg',
      opacity: 0,
    },
    {
      rotate: 0,
      opacity: 1,
    },
    'start+=0.5',
  )

  transitionIn.to(
    pageContainer,
    {
      x: '-40px',
      duration: 0.6,
    },
    'start+=0.1',
  )

  const transitionOut = gsap.timeline({
    paused: true,
    defaults: {
      ease: 'power2.out',
    },
  })

  transitionOut.to(navigation, {
    xPercent: 100,
    duration: 0.6,
  })

  transitionOut.to(
    pageContainer,
    {
      x: 0,
      duration: 0.6,
    },
    '-=0.6',
  )

  function openNavigation() {
    document.body.classList.add('is-open')
    transitionIn.seek(0).play()
    transitionIn.eventCallback('onStart', () => {
      disableBodyScroll(navigation)
      navigationOpen = true
    })
  }

  function closeNavigation() {
    if (navigationOpen) {
      document.body.classList.remove('is-open')
      transitionOut.seek(0).play()
      transitionOut.eventCallback('onStart', () => {
        enableBodyScroll(navigation)
        navigationOpen = false
      })

      if (subnavActive) {
        closeSubnavigation()
      }
    }
  }

  function openSubnavigation(parent) {
    const id = parent.getAttribute('data-id')
    const subnav = Array.from(subnavs).find((n) => {
      return n.getAttribute('data-id') === id
    })

    const tl = gsap.timeline()

    tl.fromTo(
      defaultNav,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.4,
        onComplete: () => {
          defaultNav.style.pointerEvents = 'none'
          subnavActive = true
          activeSubnav = subnav
        },
      },
    )

    tl.fromTo(
      [subnav, backBtn],
      {
        opacity: 0,
        x: '20px',
      },
      {
        opacity: 1,
        x: 0,
        duration: 0.4,
        onComplete: () => {
          subnav.style.pointerEvents = 'auto'
          backBtn.style.pointerEvents = 'auto'
        },
      },
      '-=0.4',
    )

    tl.fromTo(
      [pageContainer, navigationContainer],
      {
        x: '-=0',
      },
      {
        x: '-=40px',
        duration: 0.4,
      },
      0,
    )
  }

  function closeSubnavigation() {
    const tl = gsap.timeline()

    tl.to(defaultNav, {
      opacity: 1,
      duration: 0.6,
      onComplete: () => {
        defaultNav.style.pointerEvents = 'auto'
      },
    })

    tl.to(
      [activeSubnav, backBtn],
      {
        opacity: 0,
        x: '10px',
        duration: 0.6,
        clearProps: 'all',
        onComplete: () => {
          activeSubnav.style.pointerEvents = 'none'
          backBtn.style.pointerEvents = 'none'
          subnavActive = false
          activeSubnav = null
        },
      },
      '-=0.6',
    )

    tl.to(
      [pageContainer, navigationContainer],
      {
        x: '+=40',
      },
      0,
    )
  }

  openBtn.addEventListener('click', openNavigation, false)
  closeBtn.addEventListener('click', closeNavigation, false)
  overlay.addEventListener('click', closeNavigation, false)
  backBtn.addEventListener('click', closeSubnavigation, false)

  parents.forEach((parent) => {
    parent.addEventListener(
      'click',
      openSubnavigation.bind(null, parent),
      false,
    )
  })

  window.addEventListener('navigation:show', openNavigation, false)

  window.addEventListener(
    'navigation:out',
    () => {
      closeNavigation()
      openBtn.style.pointerEvents = 'none'
    },
    false,
  )
  window.addEventListener(
    'navigation:end',
    () => {
      openBtn.style.pointerEvents = 'auto'
    },
    false,
  )
}

export default setupNavigation
