const loadComponents = (page) => {
  const topnav = page.querySelector('[data-component="topnav"]') || null
  const fixedNav = page.querySelector('[data-component="fixed-nav"]') || null
  const hero = page.querySelector('[data-component="hero"]') || null
  const header = page.querySelector('[data-component="header"]') || null
  const videoPlayers = page.querySelectorAll('[data-component="video"]') || null
  const accordions =
    page.querySelectorAll('[data-component="accordion"]') || null
  const timelines = page.querySelectorAll('[data-component="timeline"]') || null
  const subnavigation = page.querySelector('[data-component="subnav"]') || null
  const gallery = page.querySelector('[data-component="gallery"]') || null
  const cases = page.querySelector('[data-component="cases"]') || null
  const frontQuote =
    page.querySelectorAll('[data-component="front-quote"]') || null
  const stats = page.querySelectorAll('[data-component="stats"]') || null
  const formie = page.querySelectorAll('.fui-i') || null

  if (document.querySelector('[data-component="search"]') !== null) {
    import(/* webpackChunkName: "search" */ '../components/setupSearch').then(
      ({ default: setupSearch }) => {
        setupSearch()
      },
    )
  }

  if (topnav !== null) {
    import(
      /* webpackChunkName: "hero" */ '../components/setupTopNavigation'
    ).then(({ default: setupTopNavigation }) => {
      setupTopNavigation(topnav)
    })
  }

  if (fixedNav !== null) {
    import(/* webpackChunkName: "hero" */ '../components/setupFixedNav').then(
      ({ default: setupFixedNav }) => {
        setupFixedNav(fixedNav)
      },
    )
  }

  if (hero !== null) {
    import(/* webpackChunkName: "hero" */ '../components/setupHero').then(
      ({ default: setupHero }) => {
        setupHero(hero)
      },
    )
  }

  if (header !== null) {
    import(/* webpackChunkName: "hero" */ '../components/setupHeader').then(
      ({ default: setupHeader }) => {
        setupHeader(header)
      },
    )
  }

  if (videoPlayers !== null) {
    import(
      /* webpackChunkName: "videoPlayer" */ '../components/setupVideoPlayer'
    ).then(({ default: setupVideoPlayer }) => {
      videoPlayers.forEach((player) => {
        setupVideoPlayer(player)
      })
    })
  }

  if (accordions !== null) {
    import(
      /* webpackChunkName: "accordion" */ '../components/setupAccordion'
    ).then(({ default: setupAccordion }) => {
      accordions.forEach((accordion) => {
        setupAccordion(accordion)
      })
    })
  }

  if (timelines !== null) {
    import(
      /* webpackChunkName: "timeline" */ '../components/setupTimeline'
    ).then(({ default: setupTimeline }) => {
      timelines.forEach((timeline) => {
        setupTimeline(timeline)
      })
    })
  }

  if (subnavigation !== null) {
    import(
      /* webpackChunkName: "subnavigation" */ '../components/setupSubnavigation'
    ).then(({ default: setupSubnavigation }) => {
      setupSubnavigation(subnavigation)
    })
  }

  if (gallery !== null) {
    import(/* webpackChunkName: "gallery" */ '../components/setupGallery').then(
      ({ default: setupGallery }) => {
        setupGallery(gallery)
      },
    )
  }

  if (cases !== null) {
    import(/* webpackChunkName: "cases" */ '../components/setupCases').then(
      ({ default: setupCases }) => {
        setupCases(cases)
      },
    )
  }

  if (frontQuote !== null) {
    import(
      /* webpackChunkName: "timeline" */ '../components/setupFrontQuote'
    ).then(({ default: setupFrontQuote }) => {
      frontQuote.forEach((el) => {
        setupFrontQuote(el)
      })
    })
  }

  if (stats !== null) {
    import(/* webpackChunkName: "timeline" */ '../components/setupStats').then(
      ({ default: setupStats }) => {
        stats.forEach((el) => {
          setupStats(el)
        })
      },
    )
  }

  if (formie !== null) {
    import(/* webpackChunkName: "formie" */ '../components/setupFormie').then(
      ({ default: setupFormie }) => {
        formie.forEach((el) => {
          setupFormie(el)
        })
      },
    )
  }
}

export default loadComponents
