import 'lazysizes'
import Highway from '@dogstudio/highway'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import setupNavigation from './components/setupNavigation'
import loadComponents from './global/loadComponents'

gsap.registerPlugin(ScrollToPlugin)

setupNavigation()

class DefaultRenderer extends Highway.Renderer {
  onEnter() {
    loadComponents(this.wrap.lastElementChild)
  }
}

class DefaultTransition extends Highway.Transition {
  in({ from, to, done }) {
    window.scrollTo(0, 0)

    const content = to.querySelector('[data-content]')
    const wiper = to.querySelector('[data-wiper]')
    const tl = gsap.timeline()

    content.style.opacity = 0
    wiper.style.pointerEvents = 'auto'

    from.remove()

    tl.fromTo(
      wiper,
      {
        opacity: 1,
        yPercent: 100,
      },
      {
        yPercent: 0,
        ease: 'expo.in',
        duration: 1,
      },
    )
      .set(content, {
        opacity: 1,
      })
      .to(wiper, {
        yPercent: -100,
        ease: 'expo.out',
        duration: 1,
        onComplete: done,
      })
      .eventCallback('onComplete', () => {
        if (window.location.hash) {
          gsap.to(window, {
            duration: 1,
            ease: 'expo.out',
            scrollTo: {
              y: window.location.hash,
            },
          })
        }
      })
  }

  out({ from, done }) {
    const content = from.querySelector('[data-content]')
    const wiper = from.querySelector('[data-wiper]')

    content.style.pointerEvents = 'none'
    wiper.style.pointerEvents = 'auto'

    gsap
      .timeline()
      .fromTo(
        wiper,
        {
          opacity: 1,
          yPercent: 100,
        },
        {
          yPercent: 0,
          ease: 'expo.in',
          duration: 1,
        },
      )
      .set(content, {
        opacity: 0,
      })
      .to(wiper, {
        yPercent: -100,
        ease: 'expo.out',
        duration: 1,
        onComplete: done,
      })
  }
}

const H = new Highway.Core({
  renderers: {
    default: DefaultRenderer,
  },

  transitions: {
    default: DefaultTransition,
  },
})

let lastKnownRoute

H.on('NAVIGATE_OUT', ({ from }) => {
  lastKnownRoute = new URL(from.page.URL).pathname
  window.dispatchEvent(new CustomEvent('navigation:out'))
})

H.on('NAVIGATE_IN', ({ location }) => {
  /**
   *  If navigating to a reference page, clear sessionStorage
   *  if not navigating from a case
   */
  if (/^\/(referencer|en\/cases)$/.test(location.pathname)) {
    if (!/^\/(referencer|en\/cases)\/.+$/.test(lastKnownRoute)) {
      sessionStorage.clear()

      window.fetch('/actions/my-module/default/set-filters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filters: {
            activeCategories: [],
            activeTypes: [],
            activeLocations: [],
            activeYears: [],
            activeSustainability: [],
          },
        }),
      })
    }
  }

  window.dispatchEvent(new CustomEvent('navigation:in'))
})

H.on('NAVIGATE_END', () => {
  window.dispatchEvent(new CustomEvent('navigation:end'))
})

window.addEventListener('navigation:attach', (event) => {
  H.attach(event.detail)
})
